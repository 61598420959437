'use client';

import clsx from 'clsx';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
const Navlink = ({
    theme,
    href,
    text,
    exact = false
}: {
    theme: 'black' | 'white';
    href: string;
    text: string;
    exact?: boolean; //NOTE_LT : useless
}) => {
    const pathname = usePathname();

    /* const isActive = exact
        ? pathname === href
        : pathname?.startsWith('/' + href); */

    const isActive = pathname.includes(href);
    const defaultStyles = clsx(
        'font-base',
        theme === 'black' && 'text-black',
        theme === 'white' && 'text-white'
    );
    const activeStyles = 'font-bold';
    //NOTE_LT : commenting useless code
    //const activeStyles = clsx('font-bold text-orange-default');

    return (
        <Link
            className={clsx(defaultStyles, isActive && activeStyles)}
            href={href}
        >
            <span>{text}</span>
        </Link>
    );
};

export default Navlink;
