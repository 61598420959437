const LINKS = [
    ['Nos services', 'services'],
    ['Qui sommes-nous ?', '/qui-sommes-nous'],
    ['Recrutement', '/recrutement'],
    ['F.A.Q', '/faq'],
    ['Blog', '/blog'],
    ['Contacts', '/nous-contacter']
];

export default LINKS;
