'use client';
import { Disclosure, Popover } from '@headlessui/react';
import {
    Bars3Icon,
    ChevronDownIcon,
    ChevronUpIcon
} from '@heroicons/react/24/solid';

import { VerticaleSummary } from '@/services/solutions.service';
import { AnimatePresence, motion } from 'framer-motion';
import Link from 'next/link';
import LINKS from './LINKS';

import clsx from 'clsx';
import Pennylane from '../../../public/icons/PL.svg';

function MobileNavLink({ children, ...props }: any) {
    return (
        <Popover.Button
            as={Link}
            className="block text-base leading-7 tracking-tight text-gray-700"
            {...props}
        >
            {children}
        </Popover.Button>
    );
}

const SolutionMenuMobile = ({
    solutions
}: {
    solutions: VerticaleSummary[];
}) => {
    return (
        <>
            {solutions.map((solution) => {
                return (
                    <MobileNavLink key={solution.id} href={'/' + solution.slug}>
                        Pour les {solution.name}
                    </MobileNavLink>
                );
            })}
        </>
    );
};

const MobileNav = ({ verticales }: { verticales: VerticaleSummary[] }) => {
    return (
        <Popover className="flex gap-6 2lg:hidden">
            {({ open }) => (
                <>
                    <Popover.Button
                        className={clsx(
                            'relative z-10 inline-flex items-center rounded-lg stroke-gray-900 p-1 px-2 hover:ring-pink-600 focus:outline-none focus-visible:ring-0 focus-visible:ring-white active:stroke-gray-900  sm:hover:stroke-gray-600 [&:not(:focus-visible)]:focus:outline-none',
                            open ? 'bg-gray-200/50' : 'sm:hover:bg-gray-200/50'
                        )}
                        aria-label="Toggle site navigation"
                    >
                        {({ open }) =>
                            open ? (
                                <ChevronUpIcon className="h-6 w-6 stroke-white sm:h-8 sm:w-8" />
                            ) : (
                                <Bars3Icon className="h-6 w-6  stroke-white sm:h-8 sm:w-8" />
                            )
                        }
                    </Popover.Button>
                    <AnimatePresence initial={false}>
                        {open && (
                            <>
                                <Popover.Overlay
                                    static
                                    as={motion.div}
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                    className="fixed inset-0 z-0 bg-gray-300/60 backdrop-blur"
                                />
                                <Popover.Panel
                                    static
                                    as={motion.div}
                                    initial={{
                                        opacity: 0,
                                        y: -32
                                    }}
                                    animate={{
                                        opacity: 1,
                                        y: 0
                                    }}
                                    exit={{
                                        opacity: 0,
                                        y: -32,
                                        transition: {
                                            duration: 0.2
                                        }
                                    }}
                                    className="absolute inset-x-0 top-0 z-0 origin-top rounded-b-2xl bg-gray-50 px-6 pb-6 pt-32 shadow-2xl shadow-gray-900/20"
                                >
                                    <menu className="mb-3">
                                        {LINKS.map(([label, link], index) => {
                                            if (link === 'services') {
                                                return (
                                                    <Disclosure key={index}>
                                                        <Disclosure.Button className="flex w-full items-center justify-between text-base leading-7 tracking-tight text-gray-700">
                                                            Solutions
                                                            <ChevronDownIcon
                                                                className={
                                                                    'h-4 w-4'
                                                                }
                                                            />
                                                        </Disclosure.Button>
                                                        <Disclosure.Panel className=" flex flex-col gap-2 p-3 text-gray-500">
                                                            <SolutionMenuMobile
                                                                solutions={
                                                                    verticales
                                                                }
                                                            />
                                                        </Disclosure.Panel>
                                                    </Disclosure>
                                                );
                                            }
                                            return (
                                                <MobileNavLink
                                                    href={link}
                                                    key={link}
                                                >
                                                    {label}
                                                </MobileNavLink>
                                            );
                                        })}
                                    </menu>
                                    <a
                                        href={
                                            'https://app.pennylane.com/auth/login'
                                        }
                                        target={'_blank'}
                                        rel={'noreferrer noopener'}
                                        className={
                                            'flex items-center gap-2 rounded-lg bg-white px-4 py-3 text-pennylane'
                                        }
                                    >
                                        <Pennylane
                                            className={'h-8 w-8 fill-current'}
                                        />{' '}
                                        Connexion rapide
                                    </a>
                                </Popover.Panel>
                            </>
                        )}
                    </AnimatePresence>
                </>
            )}
        </Popover>
    );
};

export default MobileNav;
