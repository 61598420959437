'use client';

import { useAnalytics } from '@/hooks/useAnalytics';
import { VerticaleSummary } from '@/services/solutions.service';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import {
    ArrowPathIcon,
    ChartPieIcon,
    CursorArrowRaysIcon,
    DocumentChartBarIcon,
    FingerPrintIcon,
    SquaresPlusIcon
} from '@heroicons/react/24/outline';
import clsx from 'clsx';
import Image from 'next/image';
import Link from 'next/link';

const solutions = [
    {
        name: 'Analytics',
        description: 'Get a better understanding of your traffic',
        href: '#',
        icon: ChartPieIcon
    },
    {
        name: 'Integrations',
        description: 'Connect with third-party tools and find out expectations',
        href: '#',
        icon: SquaresPlusIcon
    },
    {
        name: 'Engagement',
        description:
            'Speak directly to your customers with our engagement tool',
        href: '#',
        icon: CursorArrowRaysIcon
    },
    {
        name: 'Automations',
        description: 'Build strategic funnels that will convert',
        href: '#',
        icon: ArrowPathIcon
    },
    {
        name: 'Security',
        description: "Your customers' data will be safe and secure",
        href: '#',
        icon: FingerPrintIcon
    },
    {
        name: 'Reports',
        description: 'Edit, manage and create newly informed decisions',
        href: '#',
        icon: DocumentChartBarIcon
    }
];

const iconWidth = 80;

export default function FlyoutMenu({
    theme,
    verticales
}: {
    theme: 'black' | 'white';
    verticales: VerticaleSummary[];
}) {
    const { track } = useAnalytics();

    return (
        <Popover className="relative">
            <PopoverButton
                className={clsx(
                    'font-base',
                    theme === 'black' && 'text-black',
                    theme === 'white' && 'text-white',
                    'inline-flex items-center focus:outline-none focus-visible:ring-0'
                )}
            >
                <span>Solutions</span>
                <ChevronDownIcon aria-hidden="true" className="h-5 w-5" />
            </PopoverButton>

            <PopoverPanel
                transition
                className="absolute left-1/2 z-10 mt-5 flex w-screen max-w-max -translate-x-[80px] px-4 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
            >
                <div className="w-screen max-w-md flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5 lg:max-w-3xl">
                    <div className="grid grid-cols-1 gap-x-6 gap-y-1 p-4 lg:grid-cols-2">
                        {verticales.map((verticale) => (
                            <Link
                                key={verticale.id}
                                href={'/' + verticale.slug!}
                                className={
                                    'group relative flex shrink-0 grow-0 items-center gap-x-6 rounded-lg px-6 py-3 hover:bg-neutral-100'
                                }
                                onClick={() => {
                                    track('OPEN_VERTICALE', {
                                        verticale: verticale.name
                                    }).then();
                                }}
                            >
                                <div
                                    className="flex h-12 w-12 shrink-0 grow-0 items-center justify-center rounded-lg bg-perano/60 group-hover:bg-mustard-default"
                                    aria-hidden="true"
                                >
                                    <Image
                                        src={verticale.icon.url}
                                        alt={`Icone pour la verticale ${verticale.name}`}
                                        className="h-8 w-8 text-gray-600 group-hover:text-indigo-600"
                                        width={iconWidth}
                                        height={iconWidth}
                                    />
                                </div>
                                <div>
                                    <span className={'font-bold text-cyprus'}>
                                        {verticale.name}
                                    </span>
                                    <span className="absolute inset-0" />
                                    <p className="mt-1 text-xs text-gray-400">
                                        {verticale.header?.headline.replace(
                                            /[*~]+/gi,
                                            ''
                                        )}
                                    </p>
                                </div>
                            </Link>
                        ))}
                    </div>
                    <div className="bg-gray-50 px-8 py-6">
                        <div className="flex items-center gap-x-3">
                            <h3 className="text-sm font-semibold leading-6 text-gray-900">
                                Vous ne vous retrouvez pas ?
                            </h3>
                        </div>
                        <p className="mt-2 text-sm leading-6 text-gray-600">
                            Pas de panique, nous avons forcément une solution
                            pour vous !{' '}
                            <Link
                                className={'font-semibold text-cyprus'}
                                href={'/nous-contacter'}
                            >
                                Contactez-nous !
                            </Link>
                        </p>
                    </div>
                </div>
            </PopoverPanel>
        </Popover>
    );
}
